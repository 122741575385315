import React, { FC } from "react";
import classnames from "classnames";
import Link from "next/link";
import CodelexIcon from "@public/svg/codelex-logo.svg";
import WarningIcon from "@public/svg/warning-green.svg";
import MoneyIcon from "@public/svg/money-sign-invoice.svg";
import TaxesIcon from "@public/svg/taxes.svg";
import WebIcon from "@public/svg/web.svg";
import MailIcon from "@public/svg/mail-footer.svg";
import { PrivateIndividualInvoiceRequest } from "@domain/schedules/invoicing/types";
import { formatDateNative } from "@utils/date-time/common";
import { Button } from "../Button";
import styles from "./index.module.scss";

type Props = {
  invoice: PrivateIndividualInvoiceRequest;
  hideButton: boolean;
};

export const Invoice: FC<Props> = ({ invoice, hideButton }) => {
  const { amount, date, dueDate, id, recipient, wherefore, type } = invoice;
  const amountFormatted = amount.toFixed(2).replace(".", ",");

  const downloadInvoice = () => {
    const baseUrl = `/api/invoice/download-invoice`;
    const queryParams = new URLSearchParams({
      scheduleItemId: id.toString(),
      date: date.toString(),
      dueDate: dueDate.toString(),
      fullName: recipient.fullName,
      personalId: recipient.personalId || "",
      wherefore: wherefore,
      amount: amount.toString(),
    }).toString();
    const url = `${baseUrl}?${queryParams}`;
    window.open(url, "_blank");
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {!hideButton && type === "private-individual" && (
          <div className={styles.buttonWrapper}>
            <Button type={"dashed"} onClick={downloadInvoice}>
              Lejupielādēt rēķinu
            </Button>
          </div>
        )}
        <div className={styles.headWrapper}>
          <div className={styles.titleContainer}>
            <div className={styles.headTitle}>Rēķins</div>
            <div className={styles.invoiceNr}>{id}</div>
          </div>
          <div className={styles.codelexLogoWrapper}>
            <CodelexIcon className={styles.codelexLogo} />
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.contentLeftSide}>
            <div className={styles.supplier}>
              <div className={styles.contentLabel}>Piegādātājs</div>
              <div className={styles.contentTitle}>
                SIA &quot;CODELEX&quot; / Mācību centrs
              </div>
            </div>

            <div className={styles.accountWrapper}>
              <div className={styles.regNumber}>
                <div
                  className={classnames(
                    styles.contentLabel,
                    styles.constantWidth,
                  )}
                >
                  Reģ. Nr.
                </div>
                <div className={styles.contentTitle}>42103088167</div>
              </div>

              <div className={styles.pvnNr}>
                <div className={styles.contentLabel}>PVN. Maksātāja Nr.</div>
                <div className={styles.contentTitle}>LV42103088167</div>
              </div>
            </div>

            <div className={styles.adress}>
              <div className={styles.contentLabel}>Adrese</div>
              <div className={styles.contentTitle}>
                &quot;Kadaga 10&quot; - 27, Kadaga, Ādažu nov., LV-2103
              </div>
            </div>

            <div className={styles.bankWrapper}>
              <div className={styles.bank}>
                <div
                  className={classnames(
                    styles.contentLabel,
                    styles.constantWidth,
                  )}
                >
                  Banka
                </div>
                <div className={styles.contentTitle}>
                  AS &quot;SWEDBANK&quot;
                </div>
              </div>

              <div className={styles.code}>
                <div className={styles.contentLabel}>Kods</div>
                <div className={styles.contentTitle}>HABALV22</div>
              </div>
            </div>

            <div className={styles.account}>
              <div className={styles.contentLabel}>Konts</div>
              <div className={styles.contentTitle}>LV78HABA0551046287789</div>
            </div>
          </div>

          <div className={styles.contentRightSide}>
            <div className={styles.date}>
              <div className={styles.dateNow}>
                <div
                  className={classnames(
                    styles.contentLabel,
                    styles.constantWidth,
                  )}
                >
                  Datums
                </div>
                <div className={styles.contentTitle}>
                  {formatDateNative(date)}
                </div>
              </div>

              <div className={styles.dateDue}>
                <div className={styles.contentLabel}>Apmaksas termiņš</div>
                <div className={styles.contentTitle}>
                  {formatDateNative(dueDate)}
                </div>
              </div>
            </div>

            <div className={styles.date}>
              <div className={styles.dateNow}>
                <div
                  className={classnames(
                    styles.contentLabel,
                    styles.constantWidth,
                  )}
                >
                  Saņēmējs
                </div>
                <div className={styles.contentName}>{recipient.fullName}</div>
              </div>

              {recipient.personalId && (
                <div className={styles.dateDue}>
                  <div className={styles.contentLabel}>Personas kods</div>
                  <div className={styles.contentTitle}>
                    {recipient.personalId}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.whereforeWrapper}>
          <div className={styles.wherefore}>{wherefore}</div>
          <div className={styles.whereforeSum}>{amountFormatted} €</div>
        </div>

        <div className={styles.pvnWrapper}>
          <div className={styles.pvn}>
            PVN 0%, pamatojoties uz PVN likuma 52. panta 1. daļas 12. punktu
          </div>
          <div className={styles.pvnSum}>0,00 €</div>
        </div>

        <div className={styles.payDetails}>
          <div className={styles.payDetailsLeftSide}>
            <div className={styles.details}>
              <div className={styles.warningWrapper}>
                <WarningIcon />
              </div>
              <div className={styles.payTitle}>
                Maksājuma detaļās lūgums norādīt rēķina numuru:{" "}
                <span className={styles.highLight}>{id}</span>
              </div>
            </div>
          </div>
          <div className={styles.payDetailsRightSide}>
            <div className={styles.payLabel}>Kopā</div>
            <div className={styles.paySum}>{amountFormatted} €</div>
          </div>
        </div>

        <div className={styles.banner}>
          <div className={styles.bannerTop}>
            <MoneyIcon className={styles.bannerIconWrapper} />
            <div className={styles.bannerTopTitle}>
              Jaunums - Codelex Piesaistes programma! Piesaisti uzņēmumus un to
              vakances, un pretī saņem atlīdzību. Uzzini vairāk šeit:
              <span className={styles.bannerTopHighLight}>
                {" "}
                <Link href={"https://app.codelex.io/referrals"}>
                  https://app.codelex.io/referrals
                </Link>
              </span>
            </div>
          </div>
          <div className={styles.bannerBottom}>
            <TaxesIcon className={styles.bannerIconWrapper} />
            <div className={styles.bannerBottomTitle}>
              Uzzini vairāk par iespēju atgūt daļu no mācību maksas, iesniedzot
              gada ienākumu deklarāciju. Skaties šeit:
              <span className={styles.bannerBottomHighLight}>
                {" "}
                <Link
                  href={
                    "https://github.com/codelex-io/developer-handbook/tree/master/taxes"
                  }
                >
                  https://github.com/codelex-io/developer-handbook/tree/master/taxes
                </Link>
              </span>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.navigationWrapper}>
            <div className={styles.navigationHomeWrapper}>
              <WebIcon className={styles.webIcon} />
              <div className={styles.webTitle}>
                <Link href={"https://www.codelex.io/"}>www.codelex.io</Link>
              </div>
            </div>
            <div className={styles.navigationInfoWrapper}>
              <MailIcon className={styles.mailIcon} />
              <div className={styles.mailTitle}>info@codelex.io</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
